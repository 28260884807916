<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <protex-logo style="width: 150px !important; height: auto" />
      </b-link>
      <b-col lg="12" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <!-- #region main card -->
        <b-col sm="8" md="8" lg="8" style="max-width:850px;" class="px-xl-2 mx-auto">
          <b-card>
            <b-row>
            <b-col v-if="!hideLeftCol" cols="4"
            style="
            padding: 0px;
            background-repeat: no-repeat;
            background-size: fit;
            margin-top:-21px;
            margin-bottom:-21px;
            margin-left:-10px;
            border-radius: 0.428rem 0rem 0rem 0.428rem
          "
          :style="{ backgroundImage: 'url(' + bgImg + ')' }"
        >
            </b-col>
            <b-col style="padding: 24px 0px; padding-right:24px;" v-bind:style="[hideLeftCol ?{'padding-left':'24px'}: {'padding-left':'0px'}]">
            <b-card-title
              class="mb-1"
              style="font-size: 30px; font-weight: 700"
            >
              <b>Forgot Password?</b>
            </b-card-title>
            <b-card-text class="mb-2" style="color: #7A8B99; font-size:16px">
              Enter your email and we'll send you instructions to reset your
              password
            </b-card-text>

            <validation-observer ref="simpleRules">
              <b-form
                class="auth-forgot-password-form mt-2"
                @submit.prevent="AwsResetPassword"
                style="padding-top: 10px"
              >
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label
                      style="color: #596D80; font-weight: 500; font-size:14px"
                    >
                      Work Email
                    </label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="forgot-password-email"
                      v-model="username"
                      @input="(val) => (username = username.toLowerCase())"
                      style="height:58px; font-size:14px;  box-shadow: 0px 24px 48px 0 rgba(41,58,79,0.15) !important;"
                      :state="errors.length > 0 ? false : null"
                      name="forgot-password-email"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-button type="submit" variant="primary" block   
                  style="color: white !important; font-size: 18px; font-weight:500; height:56px;"
                >
                  Send reset link
                </b-button>
              </b-form>
            </validation-observer>

            <p class="text-center mt-2" style="margin-bottom: 0rem">
              <b-link
                style="font-size: 14px;
                        color: #7A8B99;
                        font-weight:500;
                        "
                id="sign-in"
                :to="{ name: 'auth-login' }"
              >
                Back to sign-in
              </b-link>
            </p>
            </b-col>
            </b-row>
          </b-card>
        </b-col>
        <!-- #endregion -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */

// #region imports
// General
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { Auth } from "aws-amplify";
import ProtexLogo from "@core/layouts/components/Logo.vue";

// Components
import {
  BRow,
  BCol,
  BLink,
  BCard,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";
//#endregion

export default {

  // #region data
  data() {
    return {
      hideLeftCol: false,
      bgImg:"./forgot-pw-bg.png",
      username: "",
      sideImg: require("@/assets/images/pages/forgot-password-v2.svg"),
      required,
      email,
    };
  },
  // #endregion

  // #region components
  components: {
    BRow,
    BCard,
    BCol,
    ProtexLogo,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  // #endregion

  // #region computed
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/forgot-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  // #endregion

  mounted() {
    this.$nextTick(() => {
      let width = document.documentElement.clientWidth;
      if (width <= 991) {
        this.hideRightCol = true;
      } else {
        this.hideRightCol = false;
      }
      window.addEventListener("resize", this.getDimensions);
    });
  },

  // #region methods
  methods: {
    getDimensions() {
      let width = document.documentElement.clientWidth;
      if (width <= 991) {
        this.hideLeftCol = true;
      } else {
        this.hideLeftCol = false;
      }
    },
    async AwsResetPassword() {
      try {
        Auth.forgotPassword(this.username)
          .then((data) => {
            this.$toast.success({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Success",
                text: "Reset code sent via Email",
              },
            });
            this.$router.replace({
              name: "auth-reset-password",
              params: { userEmail: this.username },
            });
          })
          .catch((err) => {
            this.$toast.error({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Error",
                text: err.message,
              },
            });
          });
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "Warning",
            text: error,
          },
        });
      }
    },
  },
  //#endregion
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style>
#sign-in:hover {
  color: #00a0f3 !important;
}
#userback_button_container {
  display: none !important;
}

#crisp-chatbox {
  visibility: hidden !important;
}

#crisp-chatbox > div > a > span.cc-7doi.cc-1ada > span > span.cc-15mo {
  visibility: hidden !important;
}

#crisp-chatbox > div > a > span > span > span{
  visibility: hidden !important;
}
</style>